import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux/authSlice";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Utilisez le storage adapté (localStorage, sessionStorage, etc.)

const rootReducer = combineReducers({
    auth: authReducer,
    // Ajoutez d'autres reducers si nécessaire
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // Les slices que vous souhaitez persister
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignorer les actions spécifiques à redux-persist
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);