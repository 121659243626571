import React from 'react';

const Road13SVG = () => (
    <svg width="494" height="3535" viewBox="0 0 494 3535" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2763_1031)">
            <path
                d="M265.293 0L198.388 38.8706C193.331 41.8087 193.363 49.1238 198.447 52.0167L320.139 121.275C325.23 124.172 325.253 131.503 320.179 134.432L217.227 193.859L114.275 253.287C109.175 256.23 109.231 263.61 114.374 266.477L364.303 405.049C369.516 407.873 369.616 415.32 364.48 418.283L290.781 460.803C288.463 462.14 285.612 462.156 283.279 460.846L218.43 424.425C216.124 423.13 213.31 423.13 211.004 424.425L153.958 456.464C148.843 459.337 148.788 466.68 153.858 469.629L192.465 492.088C197.516 495.026 197.485 502.332 192.41 505.228L107.124 553.888C102.034 556.792 102.022 564.126 107.102 567.047L184.717 611.809L294.188 674.945C299.231 677.881 299.206 685.175 294.142 688.076L118.518 788.713C113.436 791.625 113.434 798.953 118.513 801.868L213.65 856.479C215.994 857.825 218.878 857.821 221.219 856.469L301.753 809.927C304.093 808.575 306.975 808.57 309.319 809.914L401.763 862.925C406.837 865.835 406.846 873.151 401.778 876.072L201.658 991.641C199.309 993.002 196.412 993.002 194.062 991.643L93.9692 933.743C91.6293 932.389 88.7457 932.384 86.4006 933.729L47.8382 955.842C42.7544 958.757 42.7583 966.091 47.8453 969.001L105.355 1001.9C110.439 1004.81 110.446 1012.13 105.367 1015.05L29.3814 1058.71C24.2917 1061.64 24.3125 1068.99 29.4187 1071.88L241.393 1192.09C243.733 1193.42 246.601 1193.41 248.93 1192.06L365.887 1124.41C368.245 1123.04 371.153 1123.05 373.505 1124.42L475.887 1184.46C480.918 1187.37 480.934 1194.63 475.917 1197.56L214.161 1350.7C211.816 1352.08 208.915 1352.09 206.558 1350.73L117.906 1299.85C115.561 1298.51 112.676 1298.51 110.335 1299.87L17.7857 1353.4C12.7394 1356.32 12.7378 1363.61 17.7829 1366.53L41.911 1380.5C46.9478 1383.42 46.9566 1390.69 41.9268 1393.61L29.1736 1401.04C24.1385 1403.97 24.1539 1411.25 29.2014 1414.16L78.6714 1442.69C83.7591 1445.62 83.7241 1452.97 78.6087 1455.86L56.7312 1468.2"
                stroke="#29C129" stroke-width="7.58243"/>
        </g>
        <defs>
            <clipPath id="clip0_2763_1031">
                <rect width="494" height="3535" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Road13SVG;