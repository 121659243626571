import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getRegisteredUsers } from '../../services/statsServices';

const RegisteredUsersApexChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [granularity, setGranularity] = useState('daily'); // 'daily' or 'monthly'
    const [loading, setLoading] = useState(false);
    const [percentageIncrease, setPercentageIncrease] = useState(0); // Percentage increase

    // Fonction pour calculer le percentile
    const calculatePercentile = (data, percentile) => {
        if (!data.length) return 0;
        const sorted = [...data].sort((a, b) => a - b);
        const index = Math.floor((percentile / 100) * sorted.length);
        return sorted[index];
    };

    // Fetch data from API
    const fetchRegisteredUsersData = async (currentGranularity) => {
        setLoading(true);
        try {
            const result = await getRegisteredUsers(currentGranularity);

            if (!result || !result.chartSeries) {
                setChartSeries([]);
                setPercentageIncrease(0);
                setLoading(false);
                return;
            }

            const registeredUsersSeries = result.chartSeries.find(series => series.name === "Registered Users");
            const roobetLinkedAccountsSeries = result.chartSeries.find(series => series.name === "Accounts with Roobet Link");

            if (!registeredUsersSeries || !roobetLinkedAccountsSeries) {
                setChartSeries([]);
                setPercentageIncrease(0);
                setLoading(false);
                return;
            }

            // Préparer les données pour ApexCharts
            const categories = registeredUsersSeries.data.map(item => item.x);
            const registeredUsersData = registeredUsersSeries.data.map(item => item.y);
            const roobetLinkedAccountsData = roobetLinkedAccountsSeries.data.map(item => item.y);



            // Calculer le 95ème percentile pour définir le max de l'axe Y
            const combinedData = [...registeredUsersData, ...roobetLinkedAccountsData];
            const percentile95 = calculatePercentile(combinedData, 95);
            const yAxisMax = Math.ceil(percentile95 * 1.1); // Ajouter une marge de 10%

            // Calculer le pourcentage d'augmentation
            const totalUsers = registeredUsersData.reduce((acc, val) => acc + val, 0);
            const lastValue = registeredUsersData[registeredUsersData.length - 1] || 0;
            const increase = lastValue * 100 / totalUsers;

            console.log(totalUsers)

            // Mettre à jour l'état
            setPercentageIncrease(increase.toFixed(2)); // Arrondir à 2 décimales

            // Mettre à jour les options du graphique
            setChartOptions({
                chart: {
                    type: 'area',
                    toolbar: { show: false },
                    zoom: { enabled: true, type: 'x', autoScaleYaxis: true }
                },
                xaxis: {
                    categories: categories,
                    title: { text: 'Date' }
                },
                yaxis: {
                    title: { text: 'Count' },
                    labels: { formatter: val => Math.round(val) },
                    min: 0,
                    max: yAxisMax
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (val) {
                            return val.toLocaleString(); // Ajouter des séparateurs de milliers
                        }
                    }
                },
                stroke: { curve: 'smooth' },
                legend: { position: 'top' },
                markers: {
                    size: 4,
                    hover: {
                        size: 6
                    }
                },
                dataLabels: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            });

            setChartSeries([
                {
                    name: 'Registered Users',
                    data: registeredUsersData
                },
                {
                    name: 'Accounts with Roobet Link',
                    data: roobetLinkedAccountsData
                }
            ]);
        } catch (error) {
            console.error(error);
            setChartSeries([]);
            setPercentageIncrease(0);
        }
        setLoading(false);
    };

    // Handle granularity change
    const handleGranularityChange = (event) => {
        const newGranularity = event.target.value;
        setGranularity(newGranularity);
    };

    // Fetch data when granularity changes
    useEffect(() => {
        fetchRegisteredUsersData(granularity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [granularity]); // Only re-fetch when granularity changes

    return (
        <div className="col-xxl-6 col-xl-12">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <h6 className="text-lg mb-0">User Statistics</h6>
                        <select
                            className="form-select bg-base form-select-sm w-auto"
                            value={granularity}
                            onChange={handleGranularityChange}
                        >
                            <option value="daily">Daily</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-2 mt-8">
                        <h6 className="mb-0">
                            {chartSeries.length > 0 ? `${percentageIncrease}%` : 'No Data'}
                        </h6>
                        <span
                            className={`text-sm fw-semibold rounded-pill ${
                                percentageIncrease >= 0 ? 'bg-success-focus text-success-main' : 'bg-danger-focus text-danger-main'
                            } border br-success px-8 py-4 line-height-1 d-flex align-items-center gap-1`}
                        >
                            {percentageIncrease >= 0 ? (
                                <>
                                    <Icon icon="bxs:up-arrow" className="text-xs" />
                                    Increase
                                </>
                            ) : (
                                <>
                                    <Icon icon="bxs:down-arrow" className="text-xs" />
                                    Decrease
                                </>
                            )}
                        </span>
                    </div>
                    {loading ? (
                        <div>Chargement du graphique...</div>
                    ) : (
                        <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={264} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegisteredUsersApexChart;
