// src/services/api.js
import axios from "axios";

// Création de l'instance Axios
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

// Variable pour stocker le token
let authToken = null;

// Fonction pour définir le token
export const setAuthToken = (token) => {
    authToken = token;
};

// Intercepteur de requête pour ajouter le token aux en-têtes
instance.interceptors.request.use(
    (config) => {
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Fonction de gestion des erreurs (optionnel)
export const handleError = (error) => {
    if (error.response && error.response.data && error.response.data.message) {
        console.error('API Error:', error.response.data.message);
    } else {
        console.error('An error occurred:', error.message);
    }
};

// Export de l'instance pour être utilisée dans les services
export { instance };
