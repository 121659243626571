// src/components/ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    // Vous pouvez ajouter un indicateur de chargement si nécessaire
    // const isLoading = useSelector((state) => state.auth.isLoading);
    // if (isLoading) return <Spinner />;

    return isAuthenticated ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;
