import React from 'react';

const Road10SVG = () => (
    <svg width="494" height="3535" viewBox="0 0 494 3535" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2763_941)">
            <path opacity="0.9"
                  d="M265.717 0L198.811 38.8706C193.754 41.8087 193.787 49.1238 198.87 52.0167L320.562 121.275C325.654 124.172 325.676 131.503 320.603 134.432L217.65 193.859L114.698 253.287C109.599 256.23 109.654 263.61 114.797 266.477L364.726 405.049C369.94 407.873 370.04 415.32 364.903 418.283L291.204 460.803C288.886 462.14 286.035 462.156 283.702 460.846L218.854 424.425C216.548 423.13 213.734 423.13 211.428 424.425L154.381 456.464C149.267 459.337 149.211 466.68 154.281 469.629L192.888 492.088C197.939 495.026 197.909 502.332 192.833 505.228L107.548 553.888C102.458 556.792 102.445 564.126 107.526 567.047L185.14 611.809L294.611 674.945C299.654 677.881 299.629 685.175 294.566 688.076L118.941 788.713C113.86 791.625 113.857 798.953 118.936 801.868L214.073 856.479C216.418 857.825 219.301 857.821 221.642 856.469L302.177 809.927C304.516 808.575 307.398 808.57 309.742 809.914L402.187 862.925C407.261 865.835 407.269 873.151 402.202 876.072L202.082 991.641C199.732 993.002 196.835 993.002 194.485 991.643L94.3926 933.743C92.0527 932.389 89.1691 932.384 86.8241 933.729L48.2617 955.842C43.1778 958.757 43.1817 966.091 48.2687 969.001L105.778 1001.9C110.862 1004.81 110.87 1012.13 105.791 1015.05L29.8048 1058.71C24.7151 1061.64 24.736 1068.99 29.8421 1071.88L228.14 1184.34"
                  stroke="#29C129" stroke-width="7.58243"/>
        </g>
        <defs>
            <clipPath id="clip0_2763_941">
                <rect width="494" height="3535" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Road10SVG;