import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import IsometricMap from "../components/map/IsometricMap";


const HomePageSeven = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="NFT & Gaming" />

        {/* DashBoardLayerSeven */}
        <IsometricMap />

      </MasterLayout>
    </>
  );
};

export default HomePageSeven;
