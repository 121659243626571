import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleLogin } from '../../services/authService';

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await handleLogin(email, password);
            if (data) {
                if (data?.data?.user?.rank.userRank !== 'ADMIN') {
                    return thunkAPI.rejectWithValue('Unauthorized');
                }
                return data;
            } else {
                return thunkAPI.rejectWithValue('Échec de l\'authentification');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || 'Erreur inconnue');
        }
    }
);

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    isLoading: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signOut(state) {
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload.data.user;
                state.token = action.payload.data.token;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { signOut } = authSlice.actions;
export default authSlice.reducer;