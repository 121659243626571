import { handleError, instance } from "./api";

export const handleLogin = async (email, password) => {
    try {
        const result = await instance.post("/auth/login", { email, password });
        if (result?.status === 200) {

            console.log(result.data.data);

            localStorage.setItem("roobetReloadsAdminToken", result?.data?.data?.token);

            return result?.data;
        } else return false;
    } catch (error) {
        return false;
    }
};