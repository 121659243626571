// src/components/MapRoad/svgs/routeSvgs.js
import Road1SVG from "./assets/Road1SVG";
import Road2SVG from "./assets/Road2SVG";
import Road3SVG from "./assets/Road3SVG";
import Road4SVG from "./assets/Road4SVG";
import Road5SVG from "./assets/Road5SVG";
import Road6SVG from "./assets/Road6SVG";
import Road7SVG from "./assets/Road7SVG";
import Road8SVG from "./assets/Road8SVG";
import Road9SVG from "./assets/Road9SVG";
import Road10SVG from "./assets/Road10SVG";
import Road11SVG from "./assets/Road11SVG";
import Road12SVG from "./assets/Road12SVG";
import Road14SVG from "./assets/Road14SVG";
import Road15SVG from "./assets/Road15SVG";
import Road16SVG from "./assets/Road16SVG";
import Road17SVG from "./assets/Road17SVG";
import Road18SVG from "./assets/Road18SVG";
import Road19SVG from "./assets/Road19SVG";
import Road20SVG from "./assets/Road20SVG";
import Road22SVG from "./assets/Road22SVG";
import Road23SVG from "./assets/Road23SVG";
import Road24SVG from "./assets/Road24SVG";
import Road25SVG from "./assets/Road25SVG";
import Road26SVG from "./assets/Road26SVG";
import Road27SVG from "./assets/Road27SVG";
import Road28SVG from "./assets/Road28SVG";
import Road13SVG from "./assets/Road13SVG";
import Road21SVG from "./assets/Road21SVG";

const routeSvgs = {
    1: Road1SVG,
    2: Road2SVG,
    3: Road3SVG,
    4: Road4SVG,
    5: Road5SVG,
    6: Road6SVG,
    7: Road7SVG,
    8: Road8SVG,
    9: Road9SVG,
    10: Road10SVG,
    11: Road11SVG,
    12: Road12SVG,
    13: Road13SVG,
    14: Road14SVG,
    15: Road15SVG,
    16: Road16SVG,
    17: Road17SVG,
    18: Road18SVG,
    19: Road19SVG,
    20: Road20SVG,
    21: Road21SVG,
    22: Road22SVG,
    23: Road23SVG,
    24: Road24SVG,
    25: Road25SVG,
    26: Road26SVG,
    27: Road27SVG,
    28: Road28SVG
};

export default routeSvgs;