import React from 'react';

const Road1SVG = () => (
    <svg width="494" height="3535" viewBox="0 0 494 3535" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2763_701)">
            <path
                d="M197.095 35.5924C195.285 36.6443 194.67 38.9646 195.722 40.7751C196.774 42.5856 199.094 43.2005 200.905 42.1487L197.095 35.5924ZM264.001 -3.27812L197.095 35.5924L200.905 42.1487L267.81 3.27812L264.001 -3.27812Z"
                fill="#29C129"/>
        </g>
        <defs>
            <clipPath id="clip0_2763_701">
                <rect width="494" height="3535" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Road1SVG;