import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getPackdrawWager } from '../../services/statsServices';

const PackdrawWagerChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [granularity, setGranularity] = useState('daily'); // 'daily' or 'monthly'
    const [loading, setLoading] = useState(false);

    // Fetch data from API
    const fetchPackdrawData = async (currentGranularity) => {
        setLoading(true);
        try {
            const result = await getPackdrawWager(currentGranularity);

            if (!result || !result.chartSeries) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            const packdrawSeries = result.chartSeries[0];

            if (!packdrawSeries) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            // Prepare data for ApexCharts
            const categories = packdrawSeries.data.map(item => item.x);
            const packdrawData = packdrawSeries.data.map(item => item.y);

            // Update chart options and series
            setChartOptions({
                chart: {
                    type: 'line',
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                xaxis: {
                    categories: categories,
                    title: { text: 'Date' }
                },
                yaxis: {
                    title: { text: 'Wager Amount' },
                    labels: {
                        formatter: val => {
                            if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(1)}m`;
                            if (val >= 1_000) return `${(val / 1_000).toFixed(1)}k`;
                            return val;
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: val => {
                            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 1 }).format(val);
                        }
                    }
                },
                stroke: { curve: 'smooth' },
                legend: { position: 'top' }
            });

            setChartSeries([
                {
                    name: 'Packdraw Wager',
                    data: packdrawData
                }
            ]);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    // Handle granularity change
    const handleGranularityChange = (event) => {
        const newGranularity = event.target.value;
        setGranularity(newGranularity);
    };

    // Fetch data when granularity changes
    useEffect(() => {
        fetchPackdrawData(granularity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [granularity]); // Only re-fetch when granularity changes

    return (
        <div className="col-md-6">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">Packdraw Wager Statistics</h6>
                    <select
                        className="form-select bg-base form-select-sm w-auto"
                        value={granularity}
                        onChange={handleGranularityChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <div className="card-body p-24">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <ReactApexChart
                            id="packdrawWagerChart"
                            options={chartOptions}
                            series={chartSeries}
                            type="line"
                            height={264}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PackdrawWagerChart;
