import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getEngagementsRewards } from '../../services/statsServices';

const EngagementRewardsChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [granularity, setGranularity] = useState('daily'); // 'daily' or 'monthly'
    const [loading, setLoading] = useState(true);

    const fetchEngagementRewardsData = async () => {
        setLoading(true);
        try {
            const result = await getEngagementsRewards(granularity);

            if (!result || !result.chartSeries) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            // Transformation des données pour ApexCharts
            const categories = [];
            const seriesData = {};

            result.chartSeries.forEach((serie) => {
                if (!seriesData[serie.name]) {
                    seriesData[serie.name] = [];
                }
                serie.data.forEach((point) => {
                    if (!categories.includes(point.x)) {
                        categories.push(point.x);
                    }
                    seriesData[serie.name].push(point.y);
                });
            });

            // Préparer les séries pour ApexCharts
            const preparedSeries = Object.keys(seriesData).map((serieName) => ({
                name: serieName,
                data: seriesData[serieName],
            }));

            // Trier les catégories par ordre chronologique
            categories.sort();

            // Mettre à jour les options et séries
            setChartOptions({
                chart: {
                    type: 'line',
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                xaxis: {
                    categories: categories,
                    title: { text: 'Date' }
                },
                yaxis: {
                    title: { text: 'Rewards Amount' },
                    labels: {
                        formatter: val => {
                            if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(1)}m`;
                            if (val >= 1_000) return `${(val / 1_000).toFixed(1)}k`;
                            return val;
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                stroke: { curve: 'smooth' },
                legend: { position: 'top' }
            });

            setChartSeries(preparedSeries);
        } catch (error) {
            console.error('Erreur lors de la récupération des données du graphique:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGranularityChange = (event) => {
        setGranularity(event.target.value);
    };

    useEffect(() => {
        fetchEngagementRewardsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [granularity]); // Re-fetch data when granularity changes

    return (
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">Engagement Rewards</h6>
                    <select
                        className="form-select bg-base form-select-sm w-auto"
                        value={granularity}
                        onChange={handleGranularityChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <div className="card-body p-24">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <ReactApexChart
                            id="engagementRewardsChart"
                            options={chartOptions}
                            series={chartSeries}
                            type="line"
                            height={350}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EngagementRewardsChart;
