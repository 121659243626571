import React, { useState, useRef, useEffect } from 'react';
import { svgMap } from './maps';
import POIMarker from './poi/POIMarker';
import MapRoad from "./road/MapRoad";
import MapBuildings from "./decoration/MapBuildings";

const IsometricMap = () => {
    const [selectedPOI, setSelectedPOI] = useState(null);

    const handleSelectPOI = (poi) => {
        setSelectedPOI(poi);
    };

    // Pour la popup, on peut la positionner de différentes manières.
    // Ici, par simplicité, on la positionne de façon absolue relativement au conteneur.
    const containerRef = useRef(null);

    // Exemple simplifié : on positionne la popup près des coordonnées du POI
    // (ces coordonnées sont en système du SVG. Pour un positionnement plus précis,
    // il faudrait transformer les coordonnées SVG en coordonnées écran en utilisant
    // getBoundingClientRect() ou le CTM du SVG.)
    const [popupStyle, setPopupStyle] = useState({});

    useEffect(() => {
        if (selectedPOI && containerRef.current) {
            // Positionnez la popup arbitrairement à droite et légèrement en bas du POI.
            // Dans un cas réel, il faudrait convertir les coords du SVG en pixels.
            // Pour cet exemple, supposons juste un positionnement fixe ou ajustez selon vos besoins.
            setPopupStyle({
                position: 'absolute',
                left: selectedPOI.x + 100,
                top: selectedPOI.y + 100,
                background: '#fff',
                border: '1px solid #ccc',
                padding: '10px',
                zIndex: 999,
            });
        } else {
            setPopupStyle({});
        }
    }, [selectedPOI]);

    return (
        <div
            ref={containerRef}
            style={{ width: '100%', height: '100%', overflowY: 'auto', position: 'relative' }}
        >
            <svg {...svgMap.props}>
                {svgMap.props.children}

                {/* Vos POIs */}
                <POIMarker x={200} y={400} label="Point A" onSelect={handleSelectPOI} />
                <POIMarker x={500} y={700} label="Point B" onSelect={handleSelectPOI} />
                <MapRoad x={708} y={95} routeId={28} label="Point B" onSelect={handleSelectPOI}/>
                <MapBuildings x={670} y={1355}/>
            </svg>

            {selectedPOI && (
                <div style={popupStyle}>
                    <h3>{selectedPOI.label}</h3>
                    <p>Contenu détaillé du POI...</p>
                    <button onClick={() => setSelectedPOI(null)}>Fermer</button>
                </div>
            )}
        </div>
    );
};

export default IsometricMap;
