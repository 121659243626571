import { handleError, instance } from "./api";


export const getDailyUserWager = async () => {

    try {
        const response = await instance.get(
            "/stats/daily-user-wager",
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getRegisteredUsers = async (granularity) => {
    try {

        const response = await instance.get(
            `/stats/registered-users?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error(`Erreur lors de la récupération des données d'utilisateurs enregistrés (${granularity}):`, error);
        return false;
    }
};

export const getRoobetWager = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/wager/roobet?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getRoobetWeightedWager = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/wager/roobet/weighted?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getPackdrawWager = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/wager/packdraw?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getEngagementsRewards = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/engagements-rewards?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getGuessBalance = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/guess-balance?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getShards = async (granularity) => {
    try {
        const response = await instance.get(
            `/stats/shards?granularity=${granularity}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getTopUsersShards = async (date) => {
    try {
        const response = await instance.get(
            `/stats/top-users?date=${date}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};

export const getUnitCount = async () => {
    try {
        const response = await instance.get(
            `/stats/unit-count`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("roobetReloadsAdminToken")}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching daily user wager data:', error);
        return false;
    }
};
