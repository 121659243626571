import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getShards } from '../../services/statsServices';

const ShardsChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [granularity, setGranularity] = useState('daily'); // 'daily' ou 'monthly'
    const [loading, setLoading] = useState(true);

    const fetchShardsData = async () => {
        setLoading(true);
        try {
            const resultShard = await getShards(granularity);

            if (!resultShard || !resultShard.chartSeries) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            const totalData = resultShard.chartSeries.find(series => series.name === "Total Shards");
            const averageData = resultShard.chartSeries.find(series => series.name === "Average Shards Per User");

            if (!totalData || !averageData) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            // Préparer les données pour ApexCharts
            const categories = totalData.data.map(item => item.x); // Dates
            const totalShards = totalData.data.map(item => item.y);
            const averageShards = averageData.data.map(item => item.y);

            setChartOptions({
                chart: {
                    type: 'line',
                    toolbar: { show: false },
                    zoom: { enabled: true }
                },
                xaxis: {
                    categories: categories, // Définit les dates comme catégories
                    title: { text: 'Dates' },
                    labels: { rotate: -45, style: { fontSize: '12px' } }
                },
                yaxis: [
                    {
                        title: { text: 'Total Shards' },
                        labels: {
                            formatter: val => {
                                if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(1)}m`;
                                if (val >= 1_000) return `${(val / 1_000).toFixed(1)}k`;
                                return val;
                            }
                        }
                    },
                    {
                        opposite: true,
                        title: { text: 'Average Shards Per User' },
                        labels: {
                            formatter: val => val.toFixed(2)
                        }
                    }
                ],
                tooltip: {
                    shared: true,
                    intersect: false
                },
                stroke: { curve: 'smooth', width: 2 },
                legend: {
                    position: 'top',
                    markers: {
                        radius: 2
                    }
                }
            });

            setChartSeries([
                {
                    name: 'Total Shards',
                    data: totalShards
                },
                {
                    name: 'Average Shards Per User',
                    data: averageShards
                }
            ]);
        } catch (error) {
            console.error('Erreur lors de la récupération des données du graphique:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShardsData();
    }, [granularity]);

    const handleGranularityChange = (e) => {
        setGranularity(e.target.value);
    };

    if (loading) {
        return <div>Chargement du graphique...</div>;
    }

    return (
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">
                        Shards Statistics - {granularity === 'daily' ? 'Quotidien' : 'Mensuel'}
                    </h6>
                    <select
                        className="form-select bg-base form-select-sm w-auto"
                        value={granularity}
                        onChange={handleGranularityChange}
                    >
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <div className="card-body p-24">
                    <ReactApexChart
                        id="shardsChart"
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        height={500}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShardsChart;
