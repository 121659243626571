import React from 'react';

const Road3SVG = () => (
    <svg width="494" height="3535" viewBox="0 0 494 3535" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2763_761)">
            <path
                d="M263.81 0L196.905 38.8706C191.848 41.8087 191.88 49.1238 196.963 52.0167L318.656 121.275C323.747 124.172 323.77 131.503 318.696 134.432L215.744 193.859L112.792 253.287C107.692 256.23 107.748 263.61 112.891 266.477L284.234 361.477"
                stroke="#29C129" stroke-width="7.58243"/>
        </g>
        <defs>
            <clipPath id="clip0_2763_761">
                <rect width="494" height="3535" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Road3SVG;