import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getDailyUserWager } from '../../services/statsServices';

const COLORS = [
    '#8884d8', '#82ca9d', '#ffc658', '#d0ed57', '#a4de6c',
    '#8dd1e1', '#83a6ed', '#8a89a6', '#a05d56', '#d0743c',
    '#ff8c00'
];

const DailyUserWagerChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [loading, setLoading] = useState(true);

    const formatGroup = (group) => {
        const [min, max] = group.split('-').map(value => parseInt(value, 10));
        const formatValue = (value) => {
            if (value >= 1_000_000_000) return `$${(value / 1_000_000_000).toFixed(1)}b`;
            if (value >= 1_000_000) return `$${(value / 1_000_000).toFixed(1)}m`;
            if (value >= 1_000) return `$${(value / 1_000).toFixed(1)}k`;
            return `$${value}`;
        };
        return `${formatValue(min)} - ${formatValue(max)}`;
    };

    const fetchWagerData = async () => {
        setLoading(true);
        try {
            const result = await getDailyUserWager();

            if (!result || !result.chartSeries) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            // Transformation des données
            const dates = result.chartSeries.map(serie => serie.name); // Récupère les dates
            const groups = result.chartSeries[0].data
                .map(item => item.x) // Récupère les groupes de mise
                .sort((a, b) => parseInt(a.split('-')[0], 10) - parseInt(b.split('-')[0], 10)); // Trie par ordre croissant

            const seriesData = groups.map((group, index) => ({
                name: formatGroup(group), // Formatte les groupes
                data: result.chartSeries.map(serie => {
                    const dataPoint = serie.data.find(item => item.x === group);
                    return dataPoint ? dataPoint.y : 0; // Valeurs pour chaque date
                }),
                color: COLORS[index % COLORS.length]
            }));

            // Mettre à jour les options du graphique
            setChartOptions({
                chart: {
                    type: 'line',
                    toolbar: { show: false },
                    zoom: { enabled: true }
                },
                xaxis: {
                    categories: dates, // Définit les dates comme catégories
                    title: { text: 'Dates' },
                    labels: {
                        rotate: -45,
                        style: { fontSize: '12px' }
                    }
                },
                yaxis: {
                    title: { text: 'Nombre d\'utilisateurs' },
                    labels: {
                        formatter: val => Math.round(val)
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                stroke: { curve: 'smooth', width: 2 },
                legend: {
                    position: 'top',
                    markers: {
                        radius: 2
                    }
                }
            });

            setChartSeries(seriesData);
        } catch (error) {
            console.error('Erreur lors de la récupération des données du graphique:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWagerData();
    }, []);

    if (loading) {
        return <div>Chargement du graphique...</div>;
    }

    return (
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">Performance des Wagers Utilisateurs Quotidiens</h6>
                </div>
                <div className="card-body p-24">
                    <ReactApexChart
                        id="dailyUserWagerChart"
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        height={500}
                    />
                </div>
            </div>
        </div>
    );
};

export default DailyUserWagerChart;
