import React, { useState } from 'react';
import roadSvgs from './roadSvgs';

const MapRoad = ({ x, y, routeId, onSelect }) => {
    const [hovered, setHovered] = useState(false);

    const RoadSVG = roadSvgs[routeId];

    if (!RoadSVG) {
        console.warn(`Aucun SVG trouvé pour routeId: ${routeId}`);
        return null;
    }

    return (
        <g
            transform={`translate(${x}, ${y})`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => onSelect({ x, y, routeId })}
            style={{ cursor: 'pointer' }}
        >
            <RoadSVG />

            {hovered && (
                <text x="0" y="-10" fill="#000" fontSize="12">
                    Route {routeId}
                </text>
            )}
        </g>
    );
};

export default MapRoad;