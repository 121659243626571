import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getTopUsersShards } from '../../services/statsServices';

const TopUsersChart = () => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchTopUsersData = async (date) => {
        setLoading(true);
        try {
            const result = await getTopUsersShards(date);

            if (!result || !result.chartSeries || result.chartSeries.length === 0) {
                setChartSeries([]);
                setLoading(false);
                return;
            }

            // Transformation des données pour ApexCharts
            const usernames = result.chartSeries[0].data.map(item => item.x); // Récupère les noms d'utilisateur
            const shards = result.chartSeries[0].data.map(item => item.y); // Récupère les shards associés

            setChartOptions({
                chart: {
                    type: 'bar',
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                xaxis: {
                    categories: usernames, // Définit les noms d'utilisateur sur l'axe X
                    title: { text: 'Users' },
                    labels: { rotate: -45, style: { fontSize: '12px' } }
                },
                yaxis: {
                    title: { text: 'Shards' },
                    labels: {
                        formatter: val => {
                            if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(1)}m`;
                            if (val >= 1_000) return `${(val / 1_000).toFixed(1)}k`;
                            return val;
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: val => {
                            if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(1)}m shards`;
                            if (val >= 1_000) return `${(val / 1_000).toFixed(1)}k shards`;
                            return `${val} shards`;
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '60%'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#8884d8']
            });

            setChartSeries([
                {
                    name: 'Shards',
                    data: shards
                }
            ]);
        } catch (error) {
            console.error('Erreur lors de la récupération des données du graphique:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setSelectedDate(newDate);

        if (newDate) {
            fetchTopUsersData(newDate);
        }
    };

    useEffect(() => {
        // Chargement initial : utiliser la date du jour par défaut
        const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
        setSelectedDate(today);
        fetchTopUsersData(today);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <div>Chargement des données...</div>;
    }

    return (
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">
                        Top Users - Shards (Date: {selectedDate})
                    </h6>
                    <div style={{ marginTop: '1rem' }}>
                        <label>
                            Sélectionnez une date :{' '}
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                style={{ marginLeft: '0.5rem' }}
                            />
                        </label>
                    </div>
                </div>
                <div className="card-body p-24">
                    {chartSeries.length > 0 ? (
                        <ReactApexChart
                            id="topUsersChart"
                            options={chartOptions}
                            series={chartSeries}
                            type="bar"
                            height={500}
                        />
                    ) : (
                        <div>Aucune donnée disponible pour cette date.</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopUsersChart;
