import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { getUnitCount } from '../../services/statsServices';

const UnitCountOne = () => {
    const [unitCount, setUnitCount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUnitCount = async () => {
            try {
                const data = await getUnitCount();
                if (data) {
                    setUnitCount(data);
                } else {
                    setError('Aucune donnée reçue.');
                }
            } catch (err) {
                setError('Erreur lors de la récupération des données.');
            } finally {
                setLoading(false);
            }
        };

        fetchUnitCount();
    }, []);

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="row row-cols-xxxl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-4">
            {/* Total Users */}
            <div className="col">
                <div className="card shadow-none border bg-gradient-start-1 h-100">
                    <div className="card-body p-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <div>
                                <p className="fw-medium text-primary-light mb-1">Total Users</p>
                                <h6 className="mb-0">{unitCount.totalUsers.toLocaleString()}</h6>
                            </div>
                            <div className="w-50-px h-50-px bg-cyan rounded-circle d-flex justify-content-center align-items-center">
                                <Icon
                                    icon="gridicons:multiple-users"
                                    className="text-white text-2xl mb-0"
                                />
                            </div>
                        </div>
                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0">
                            {/* Vous pouvez ajuster ou supprimer cette ligne si vous n'avez pas de données de variation */}
                        </p>
                    </div>
                </div>
                {/* card end */}
            </div>

            {/* Roobet Wager */}
            <div className="col">
                <div className="card shadow-none border bg-gradient-start-2 h-100">
                    <div className="card-body p-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <div>
                                <p className="fw-medium text-primary-light mb-1">Roobet Wager</p>
                                <h6 className="mb-0">${unitCount.roobetWager.toLocaleString()}</h6>
                            </div>
                            <div className="w-50-px h-50-px bg-purple rounded-circle d-flex justify-content-center align-items-center">
                                <Icon
                                    icon="fa-solid:money-bill"
                                    className="text-white text-2xl mb-0"
                                />
                            </div>
                        </div>
                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0">
                            {/* Ajoutez des informations supplémentaires si disponibles */}
                        </p>
                    </div>
                </div>
                {/* card end */}
            </div>

            {/* Packdraw Wager */}
            <div className="col">
                <div className="card shadow-none border bg-gradient-start-3 h-100">
                    <div className="card-body p-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <div>
                                <p className="fw-medium text-primary-light mb-1">Packdraw Wager</p>
                                <h6 className="mb-0">${unitCount.packdrawWager.toLocaleString()}</h6>
                            </div>
                            <div className="w-50-px h-50-px bg-info rounded-circle d-flex justify-content-center align-items-center">
                                <Icon
                                    icon="fa-solid:coins"
                                    className="text-white text-2xl mb-0"
                                />
                            </div>
                        </div>
                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0">
                            {/* Ajoutez des informations supplémentaires si disponibles */}
                        </p>
                    </div>
                </div>
                {/* card end */}
            </div>

            {/* Circulating Shards */}
            <div className="col">
                <div className="card shadow-none border bg-gradient-start-4 h-100">
                    <div className="card-body p-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <div>
                                <p className="fw-medium text-primary-light mb-1">Circulating Shards</p>
                                <h6 className="mb-0">{unitCount.circulatingShards.toLocaleString()}</h6>
                            </div>
                            <div className="w-50-px h-50-px bg-success-main rounded-circle d-flex justify-content-center align-items-center">
                                <Icon
                                    icon="fa-solid:gem"
                                    className="text-white text-2xl mb-0"
                                />
                            </div>
                        </div>
                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0">
                            {/* Ajoutez des informations supplémentaires si disponibles */}
                        </p>
                    </div>
                </div>
                {/* card end */}
            </div>

            {/* Shards per User */}
            <div className="col">
                <div className="card shadow-none border bg-gradient-start-5 h-100">
                    <div className="card-body p-20">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <div>
                                <p className="fw-medium text-primary-light mb-1">Shards per User</p>
                                <h6 className="mb-0">{unitCount.shardsPerUser.toFixed(2)}</h6>
                            </div>
                            <div className="w-50-px h-50-px bg-red rounded-circle d-flex justify-content-center align-items-center">
                                <Icon
                                    icon="fa6-solid:coins"
                                    className="text-white text-2xl mb-0"
                                />
                            </div>
                        </div>
                        <p className="fw-medium text-sm text-primary-light mt-12 mb-0">
                            {/* Ajoutez des informations supplémentaires si disponibles */}
                        </p>
                    </div>
                </div>
                {/* card end */}
            </div>
        </div>
    );
};

export default UnitCountOne;
