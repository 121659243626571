import React, { useState } from 'react';

const POIMarker = ({ x, y }) => {

    const svg = (
        <svg width="542" height="854" viewBox="0 0 542 854" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M386.473 78.071L435.842 49.6057V144.856L386.473 173.322V78.071Z"
                  fill="url(#paint0_linear_2679_3167)"/>
            <path d="M386.473 78.071L435.842 49.6057V53.8228L386.473 82.2775V78.071Z" fill="#754AE9"/>
            <path d="M345.609 54.5005L386.474 78.074V173.338L345.609 149.764V54.5005Z" fill="#914DE3"/>
            <path d="M373.228 161.83L370.139 160.049V177.078L373.228 178.859V161.83Z" fill="#914DE3"/>
            <path d="M373.227 161.83L376.268 160.075V177.067L373.227 178.822V161.83Z" fill="#632EAF"/>
            <path d="M346.327 146.306L343.238 144.525V161.567L346.327 163.349V146.306Z" fill="#914DE3"/>
            <path d="M373.228 161.83L370.139 160.049V166.78L373.228 168.562V161.83Z" fill="#7A3DC4"/>
            <path d="M346.327 146.306L343.238 144.525V151.256L346.327 153.038V146.306Z" fill="#7A3DC4"/>
            <path d="M346.328 146.308L349.37 144.553V161.545L346.328 163.301V146.308Z" fill="#632EAF"/>
            <path d="M371.476 162.497L335.744 141.881V145.428L371.476 166.047V162.497Z" fill="#914DE3"/>
            <path d="M371.48 162.499L385.864 154.2V157.847L371.48 166.15V162.499Z" fill="#632EAF"/>
            <path d="M335.66 141.828L350.041 133.528L385.86 154.187L371.479 162.486L335.66 141.828Z" fill="#A585E9"/>
            <path d="M350.953 56.3267L357.743 60.238V71.4238L350.953 67.5125V56.3267Z" fill="#371E83"/>
            <path d="M350.953 75.824L357.743 79.7432V90.929L350.953 87.0098V75.824Z" fill="#371E83"/>
            <path d="M363.949 46.5776L370.742 50.4969V61.6827L363.949 57.7634V46.5776Z" fill="#371E83"/>
            <path d="M399.119 89.5522L392.326 93.4715V97.6385L399.119 93.7219V89.5522Z" fill="#371E83"/>
            <path d="M399.119 97.2981L392.326 101.209V105.376L399.119 101.473V97.2981Z" fill="#371E83"/>
            <path d="M399.119 104.52L392.326 108.44V112.609L399.119 108.69V104.523V104.52Z" fill="#371E83"/>
            <path d="M399.119 112.016L392.326 115.935V120.105L399.119 116.188V112.016Z" fill="#371E83"/>
            <path d="M399.119 119.828L392.326 123.747V127.917L399.119 124V119.828Z" fill="#371E83"/>
            <path d="M408.622 84.0776L401.83 87.9942V92.1639L408.622 88.2446V84.0776Z" fill="#371E83"/>
            <path d="M408.622 91.8164L401.83 95.733V99.8921L408.622 95.9755V91.8164Z" fill="#FFE6AD"/>
            <path d="M408.622 99.0378L401.83 102.957V107.127L408.622 103.207V99.0378Z" fill="#FFE6AD"/>
            <path d="M408.622 106.521L401.83 110.435V114.604L408.622 110.698V106.521Z" fill="#FFE6AD"/>
            <path d="M408.622 114.346L401.83 118.265V122.435L408.622 118.516V114.346Z" fill="#371E83"/>
            <path d="M417.867 78.738L411.074 82.6573V86.8269L417.867 82.9077V78.738Z" fill="#371E83"/>
            <path d="M417.867 86.4844L411.074 90.4036V94.5733L417.867 90.6672V86.4844Z" fill="#371E83"/>
            <path d="M417.867 93.7061L411.074 97.6227V101.792L417.867 97.8757V93.7061Z" fill="#FFE6AD"/>
            <path d="M417.867 101.191L411.074 105.111V109.278L417.867 105.361V101.191Z" fill="#371E83"/>
            <path d="M417.867 109.014L411.074 112.931V117.103L417.867 113.184V109.014Z" fill="#371E83"/>
            <path d="M362.555 99.9207L369.347 103.845V115.031L362.555 111.112V99.9207Z" fill="#FFE6AD"/>
            <path d="M362.555 118.985L369.347 122.901V134.087L362.555 130.168V118.985Z" fill="#371E83"/>
            <path d="M345.609 54.5005L386.474 78.074V82.291L345.609 58.707V54.5005Z" fill="#914DE3"/>
            <path d="M394.976 26.0193L435.841 49.5928L386.472 78.058L345.609 54.4977L394.976 26.0325V26.0193Z"
                  fill="#A585E9"/>
            <path d="M394.393 29.5955L429.639 49.9297L387.057 74.4942L351.811 54.1599L394.393 29.5955Z" fill="#1B2C83"/>
            <path d="M393.919 32.5027L426.891 51.5217L387.061 74.4942L354.088 55.4779L393.919 32.5027Z" fill="#8866EC"/>
            <path d="M387.713 31.87L357.486 14.4324V55.5489L387.713 72.9812V31.87Z" fill="#9393FF"/>
            <path d="M387.713 31.87L357.486 14.4324V55.5489L387.713 72.9812V31.87Z" fill="#914DE3"/>
            <path d="M387.713 31.87L357.486 14.4324V17.0074L387.713 34.445V31.87Z" fill="#914DE3"/>
            <path d="M412.731 17.4348L387.711 31.8678V72.9843L412.731 58.5461V17.4348Z"
                  fill="url(#paint1_linear_2679_3167)"/>
            <path d="M409.619 29.3508L405.459 31.7493V38.602L409.619 36.2009V29.3508Z" fill="#142162"/>
            <path d="M409.619 41.0583L405.459 43.4568V50.3096L409.619 47.9111V41.0583Z" fill="#142162"/>
            <path d="M402.36 33.5386L398.203 35.937V42.7898L402.36 40.3887V33.5386Z" fill="#142162"/>
            <path d="M379.975 38.8972L384.134 41.2957V43.8496L379.975 41.4512V38.8972Z" fill="#382085"/>
            <path d="M379.975 43.6414L384.134 46.0398V48.5938L379.975 46.1927V43.6414Z" fill="#382085"/>
            <path d="M379.975 48.0616L384.134 50.4627V53.014L379.975 50.605V48.051V48.0616Z" fill="#382085"/>
            <path d="M379.975 52.6448L384.134 55.0432V57.5946L379.975 55.1961V52.6448Z" fill="#382085"/>
            <path d="M379.975 57.436L384.134 59.8345V62.3858L379.975 59.9874V57.436Z" fill="#382085"/>
            <path d="M374.156 35.5393L378.313 37.9378V40.4917L374.156 38.0933V35.5393Z" fill="#382085"/>
            <path d="M374.156 40.2832L378.313 42.6817V45.2356L374.156 42.8372V40.2832Z" fill="#FFD500"/>
            <path d="M374.156 44.7061L378.313 47.1045V49.6558L374.156 47.2574V44.7061Z" fill="#FFD500"/>
            <path d="M374.156 49.2869L378.313 51.6853V54.2367L374.156 51.8382V49.2869Z" fill="#FFD500"/>
            <path d="M374.156 54.0786L378.313 56.4771V59.039L374.156 56.6405V54.0892V54.0786Z" fill="#382085"/>
            <path d="M368.494 32.2759L372.654 34.6743V37.2257L368.494 34.8272V32.2759Z" fill="#382085"/>
            <path d="M365.031 37.0178L369.191 39.4163V41.9703L365.031 39.5718V37.0178Z" fill="#371E83"/>
            <path d="M368.494 41.4404L372.654 43.8389V46.3876L368.494 43.9891V41.4404Z" fill="#FFD500"/>
            <path d="M368.494 46.0237L372.654 48.4221V50.9761L368.494 48.5776V46.0237Z" fill="#382085"/>
            <path d="M368.494 50.813L372.654 53.2115V55.7654L368.494 53.367V50.813Z" fill="#382085"/>
            <path d="M402.36 45.2463L398.203 47.6448V54.4976L402.36 52.0991V45.2463Z" fill="#FFD500"/>
            <path d="M412.731 17.4348L387.711 31.8678V34.4428L412.731 20.0099V17.4348Z" fill="#754AE9"/>
            <path d="M382.506 0L357.486 14.4329L387.71 31.8706L412.73 17.4376L382.506 0Z" fill="#A585E9"/>
            <path d="M382.865 2.19019L361.293 14.6385L387.365 29.6803L408.945 17.2293L382.865 2.19019Z" fill="#25358A"/>
            <path d="M383.157 3.97168L362.973 15.6134L387.357 29.68L407.541 18.0356L383.157 3.97168Z" fill="#8866EC"/>
            <path d="M383.23 13.4047L376.717 9.64624V11.0906L383.23 14.8491V13.4021V13.4047Z" fill="#8484FF"/>
            <path d="M389.743 9.64917L383.23 13.405V14.8494L389.743 11.0935V9.64917Z" fill="#8E8EFF"/>
            <path d="M383.23 5.89062L376.717 9.64646L383.23 13.4049L389.74 9.64646L383.23 5.89062Z" fill="#CDCDFF"/>
            <path d="M124.235 804.351L118.834 807.463V833.766L124.235 830.649V804.351Z" fill="#4B1E82"/>
            <path d="M113.441 804.35L118.832 807.463V833.766L113.441 830.648V804.35Z" fill="#6029A3"/>
            <path d="M139.386 779.775L118.979 791.563V815.139L139.386 803.351V779.775Z" fill="#8238BF"/>
            <path d="M98.5664 779.774L118.976 791.562V815.139L98.5664 803.345V779.774Z" fill="#914DE3"/>
            <path d="M118.979 767.991L98.5664 779.774L118.976 791.562L139.386 779.774L118.979 767.991Z" fill="#B06DF4"/>
            <path d="M129.212 776.971L118.979 782.881V787.399L129.212 781.492V776.971Z" fill="#8238BF"/>
            <path d="M108.746 776.971L118.977 782.881V787.399L108.746 781.492V776.971Z" fill="#914DE3"/>
            <path d="M118.977 771.065L108.746 776.972L118.977 782.881L129.211 776.972L118.977 771.065Z" fill="#B06DF4"/>
            <path d="M161.065 823.849L155.664 826.961V853.264L161.065 850.147V823.849Z" fill="#4B1E82"/>
            <path d="M150.271 823.849L155.662 826.961V853.264L150.271 850.146V823.849Z" fill="#6029A3"/>
            <path d="M176.214 799.273L155.807 811.061V834.637L176.214 822.849V799.273Z" fill="#8238BF"/>
            <path d="M135.396 799.273L155.806 811.061V834.637L135.396 822.843V799.273Z" fill="#914DE3"/>
            <path d="M155.809 787.49L135.396 799.272L155.806 811.06L176.216 799.272L155.809 787.49Z" fill="#B06DF4"/>
            <path d="M166.04 796.469L155.807 802.379V806.897L166.04 800.99V796.469Z" fill="#8238BF"/>
            <path d="M145.574 796.469L155.805 802.379V806.897L145.574 800.99V796.469Z" fill="#914DE3"/>
            <path d="M155.805 790.563L145.574 796.47L155.805 802.38L166.039 796.47L155.805 790.563Z" fill="#B06DF4"/>
            <path d="M1.89844 200.788L70.8532 240.838V315.103L1.89844 275.052V200.788Z" fill="#AA2EFF"/>
            <path d="M1.89844 200.788L70.8532 240.838V315.103L1.89844 275.052V200.788Z" fill="#914DE3"/>
            <path d="M1.89844 200.788L70.8532 240.838V248.293L1.89844 208.243V200.788Z" fill="#7346E1"/>
            <path d="M1.89844 212.751L70.8532 252.808V258.179L1.89844 218.129V212.751Z" fill="#382085"/>
            <path d="M1.89844 235.75L70.8532 275.8V281.171L1.89844 241.116V235.75Z" fill="#382085"/>
            <path d="M1.89844 258.903L70.8532 298.955V304.326L1.89844 264.274V258.903Z" fill="#382085"/>
            <path d="M1.89844 224.404L70.8532 264.45V269.823L1.89844 229.775V224.404Z" fill="#382085"/>
            <path d="M1.89844 247.396L70.8532 287.447V292.815L1.89844 252.768V247.396Z" fill="#382085"/>
            <path d="M70.8516 240.838L100.057 223.876V298.092L70.8516 315.057V240.838Z"
                  fill="url(#paint2_linear_2679_3167)"/>
            <path d="M70.8516 240.838L100.057 223.876V231.28L70.8516 248.245V240.838Z" fill="#6835BD"/>
            <path d="M70.8516 252.808L77.3298 249.047V254.384L70.8516 258.147V252.808Z" fill="#382085"/>
            <path d="M70.8516 275.8L77.3298 272.037V277.37L70.8516 281.133V275.8Z" fill="#382085"/>
            <path d="M70.8516 298.955L77.3298 295.192V300.524L70.8516 304.287V298.955Z" fill="#382085"/>
            <path d="M70.8516 264.449L77.3298 260.688V266.021L70.8516 269.784V264.449Z" fill="#382085"/>
            <path d="M70.8516 287.446L77.3298 283.686V289.018L70.8516 292.781V287.446Z" fill="#382085"/>
            <path d="M80.1309 247.422L86.6091 243.659V248.991L80.1309 252.754V247.422Z" fill="#FFE6AD"/>
            <path d="M80.1309 270.411L86.6091 266.648V271.982L80.1309 275.743V270.411Z" fill="#382085"/>
            <path d="M80.1309 293.565L86.6091 289.802V295.134L80.1309 298.897V293.565Z" fill="#382085"/>
            <path d="M80.1309 259.068L86.6091 255.3V260.632L80.1309 264.395V259.068Z" fill="#382085"/>
            <path d="M80.1309 282.064L86.6091 278.301V283.633L80.1309 287.396V282.064Z" fill="#382085"/>
            <path d="M89.4277 242.054L95.906 238.294V243.633L89.4277 247.396V242.054Z" fill="#382085"/>
            <path d="M89.4277 265.049L95.906 261.286V266.618L89.4277 270.381V265.049Z" fill="#382085"/>
            <path d="M89.4277 288.201L95.906 284.438V289.77L89.4277 293.533V288.201Z" fill="#FFE6AD"/>
            <path d="M89.4277 253.699L95.906 249.936V255.268L89.4277 259.031V253.699Z" fill="#382085"/>
            <path d="M89.4277 276.695L95.906 272.932V278.266L89.4277 282.027V276.695Z" fill="#382085"/>
            <path d="M0 198.281L70.1803 239.042V246.498L0 205.734V198.281Z" fill="#914DE3"/>
            <path d="M70.1797 239.058L101.975 220.591V228.046L70.1797 246.504V239.058Z" fill="#7244DE"/>
            <path d="M31.7976 179.811L101.983 220.575L70.1963 239.044L0 198.278L31.7976 179.811Z" fill="#A585E9"/>
            <path d="M31.7955 179.811L101.421 220.25L70.1783 238.397L0.552734 197.958L31.7955 179.811Z" fill="#A585E9"/>
            <path d="M31.9717 183.896L93.8774 219.842L69.7404 233.867L7.84375 197.912L31.9717 183.896Z" fill="#4A29AE"/>
            <path d="M31.7987 186.781L91.3101 221.346L69.7425 233.868L10.2402 199.301L31.7987 186.781Z" fill="#8866EC"/>
            <path d="M13.4609 197.786L41.2907 213.955V216.183L13.4609 200.019V197.786Z" fill="#4A29AE"/>
            <path d="M41.293 213.957L54.3631 206.367V208.597L41.293 216.183V213.957Z" fill="#7244DE"/>
            <path d="M26.5379 190.196L54.3699 206.362L41.2998 213.952L13.4609 197.786L26.5379 190.196Z" fill="#D9F2FF"/>
            <path d="M26.5379 190.196L54.204 206.264L41.2998 213.76L13.627 197.69L26.5379 190.196Z" fill="#A585E9"/>
            <path d="M87.3308 220.662L76.373 227.026V227.904L87.3308 221.54V220.662Z" fill="#7244DE"/>
            <path d="M76.37 227.028L71.2266 224.041V224.919L76.37 227.906V227.028Z" fill="#4A29AE"/>
            <path d="M82.182 217.674L71.2266 224.038L76.37 227.025L87.3277 220.664L82.182 217.674Z" fill="#D9F2FF"/>
            <path d="M82.1828 217.675L71.291 224L76.3708 226.95L87.2626 220.625L82.1828 217.675Z" fill="#A585E9"/>
            <path d="M149.957 313.768L162.48 306.525V330.989L149.957 338.232V313.768Z" fill="#6936C0"/>
            <path d="M130.053 325.278L148.152 314.808V337.192L130.053 347.663V325.278Z" fill="#382085"/>
            <path d="M149.955 313.768V338.232L148.153 337.19V312.722L149.955 313.768Z" fill="#914DE3"/>
            <path d="M130.057 325.278V347.663L83.1378 320.514V298.125L130.057 325.278Z" fill="#382085"/>
            <path d="M130.057 325.278V347.663L128.283 346.637V324.254L130.057 325.278Z" fill="#211F63"/>
            <path d="M130.057 325.278V347.663L131.83 346.637V324.254L130.057 325.278Z" fill="#211F63"/>
            <path d="M137.334 321.069V343.453L139.105 342.427V320.043L137.334 321.069Z" fill="#211F63"/>
            <path d="M144.756 316.769V339.153L146.527 338.127V315.743L144.756 316.769Z" fill="#211F63"/>
            <path d="M123.18 321.299V343.683L121.406 342.657V320.273L123.18 321.299Z" fill="#211F63"/>
            <path d="M109.635 313.462V335.845L107.862 334.82V312.436L109.635 313.462Z" fill="#211F63"/>
            <path d="M95.8164 305.468V327.852L94.0432 326.826V304.442L95.8164 305.468Z" fill="#211F63"/>
            <path d="M116.461 317.405V339.794L114.688 338.768V316.385L116.461 317.405Z" fill="#211F63"/>
            <path d="M130.057 330.793V333.097L83.1378 305.946V303.644L130.057 330.793Z" fill="#211F63"/>
            <path d="M130.057 345.356V347.663L83.1378 320.514V318.209L130.057 345.356Z" fill="#211F63"/>
            <path d="M130.057 330.793V333.098L148.158 322.626V320.316L130.057 330.793Z" fill="#211F63"/>
            <path d="M130.053 345.356V347.663L148.152 337.191V334.885L130.053 345.358V345.356Z" fill="#211F63"/>
            <path d="M102.914 309.584V331.957L101.141 330.931V308.549L102.914 309.584Z" fill="#211F63"/>
            <path d="M89.0977 301.581V323.963L87.3244 322.937V300.553L89.0977 301.581Z" fill="#211F63"/>
            <path d="M128.821 313.014L162.475 293.552V306.541L128.821 326.003V313.014Z" fill="#7448E6"/>
            <path d="M128.82 313.015V326.003L80.1555 297.847V284.858L128.82 313.015Z" fill="#914DE3"/>
            <path d="M113.812 265.385L162.477 293.543L128.822 313.015L80.1567 284.858L113.812 265.385Z" fill="#A585E9"/>
            <path d="M113.833 267.109L159.496 293.529L128.796 311.29L83.1348 284.871L113.833 267.109Z" fill="#4A29AE"/>
            <path d="M113.804 269.321L157.568 294.644L128.796 311.29L85.0312 285.969L113.804 269.321Z" fill="#8866EC"/>
            <path d="M497.106 407.331L453.857 382.173V392.965L497.106 418.122V407.331Z" fill="#914DE3"/>
            <path d="M478.336 396.409L453.857 382.173V392.965L478.336 407.2V396.409Z" fill="#914DE3"/>
            <path d="M497.035 407.281L541.204 381.593V392.385L497.035 418.075V407.281Z" fill="#6029A3"/>
            <path d="M498.026 356.485L453.857 382.174L497.106 407.331L541.275 381.643L498.026 356.485Z" fill="#8238BF"/>
            <path d="M535.248 249.744L497.104 271.93V404.125L535.248 381.926V249.731V249.744Z"
                  fill="url(#paint3_linear_2679_3167)"/>
            <path d="M497.105 271.93L458.854 249.683V381.879L497.105 404.136V271.941V271.93Z" fill="#914DE3"/>
            <path d="M471.624 266.927L465.078 263.121V385.424L471.624 389.23V266.927Z"
                  fill="url(#paint4_linear_2679_3167)"/>
            <path d="M479.89 271.734L473.344 267.928V390.245L479.89 394.053V271.734Z"
                  fill="url(#paint5_linear_2679_3167)"/>
            <path d="M488.673 276.837L482.129 273.031V395.402L488.673 399.21V276.837Z"
                  fill="url(#paint6_linear_2679_3167)"/>
            <path d="M496.957 227.473L458.812 249.658L497.107 271.929L535.254 249.744L496.957 227.473Z" fill="#8866EC"/>
            <path d="M496.957 227.473L459.297 249.377L497.105 271.365L534.767 249.463L496.957 227.473Z" fill="#8866EC"/>
            <path d="M247.723 520.551L242.322 523.663V549.967L247.723 546.849V520.551Z" fill="#432895"/>
            <path d="M236.93 520.551L242.32 523.663V549.966L236.93 546.849V520.551Z" fill="#4E359C"/>
            <path d="M262.872 495.975L242.465 507.763V531.34L262.872 519.551V495.975Z" fill="#613DAF"/>
            <path d="M222.055 495.975L242.464 507.763V531.339L222.055 519.546V495.975Z" fill="#7653E0"/>
            <path d="M242.467 484.192L222.055 495.975L242.464 507.763L262.874 495.975L242.467 484.192Z" fill="#8866EC"/>
            <path d="M252.699 493.171L242.465 499.081V503.6L252.699 497.693V493.171Z" fill="#613DAF"/>
            <path d="M232.234 493.171L242.465 499.081V503.6L232.234 497.693V493.171Z" fill="#7653E0"/>
            <path d="M242.465 487.265L232.234 493.172L242.465 499.082L252.699 493.172L242.465 487.265Z" fill="#8866EC"/>
            <defs>
                <linearGradient id="paint0_linear_2679_3167" x1="411.157" y1="49.6057" x2="411.157" y2="173.322"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#7A50F8"/>
                    <stop offset="1" stop-color="#6029A3"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2679_3167" x1="400.221" y1="17.4348" x2="400.221" y2="72.9843"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#7A50F8"/>
                    <stop offset="1" stop-color="#6029A3"/>
                </linearGradient>
                <linearGradient id="paint2_linear_2679_3167" x1="85.4543" y1="223.876" x2="85.4543" y2="315.057"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#7A50F8"/>
                    <stop offset="1" stop-color="#6029A3"/>
                </linearGradient>
                <linearGradient id="paint3_linear_2679_3167" x1="516.176" y1="249.731" x2="516.176" y2="404.125"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#7A50F8"/>
                    <stop offset="1" stop-color="#6029A3"/>
                </linearGradient>
                <linearGradient id="paint4_linear_2679_3167" x1="468.351" y1="263.121" x2="468.351" y2="389.23"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#26106A"/>
                    <stop offset="1" stop-color="#442A97"/>
                </linearGradient>
                <linearGradient id="paint5_linear_2679_3167" x1="476.617" y1="267.928" x2="476.617" y2="394.053"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#26106A"/>
                    <stop offset="1" stop-color="#442A97"/>
                </linearGradient>
                <linearGradient id="paint6_linear_2679_3167" x1="485.401" y1="273.031" x2="485.401" y2="399.21"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#26106A"/>
                    <stop offset="1" stop-color="#442A97"/>
                </linearGradient>
            </defs>
        </svg>
    );


    return (
        <g
            transform={`translate(${x}, ${y})`}
        >
            {svg}
        </g>
    );
};

export default POIMarker;
