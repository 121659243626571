import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../store/redux/authSlice';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignInLayer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    const auth = useSelector((state) => state.auth);
    const { isAuthenticated, isLoading, error } = auth;

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/'); // Redirigez vers la page d'accueil ou une autre page protégée
            toast.success('Connexion réussie!');
        }
        if (error) {
            toast.error(error);
        }
    }, [isAuthenticated, error, navigate]);

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser(credentials));
    };

    return (
        <section className="auth bg-base d-flex flex-wrap justify-content-center">
            <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
                <div className="max-w-464-px mx-auto w-100">
                    <div>
                        <Link to="/" className="mb-40 max-w-290-px">
                            <img src="assets/images/roobetreloads/logo.svg" alt="Logo" />
                        </Link>
                        <h4 className="mb-12">Sign In to your Account</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="icon-field mb-16">
                            <span className="icon top-50 translate-middle-y">
                                <Icon icon="mage:email" />
                            </span>
                            <input
                                type="email"
                                name="email"
                                className="form-control h-56-px bg-neutral-50 radius-12"
                                placeholder="Email"
                                value={credentials.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="position-relative mb-20">
                            <div className="icon-field">
                                <span className="icon top-50 translate-middle-y">
                                    <Icon icon="solar:lock-password-outline" />
                                </span>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control h-56-px bg-neutral-50 radius-12"
                                    id="your-password"
                                    placeholder="Password"
                                    value={credentials.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <span
                                className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                                onClick={() => {
                                    const passwordInput = document.getElementById('your-password');
                                    const type = passwordInput.type === 'password' ? 'text' : 'password';
                                    passwordInput.type = type;
                                }}
                            >
                                <Icon icon="ri-eye-line" />
                            </span>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-8"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Connexion...' : 'Sign In'}
                        </button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
}

export default SignInLayer;
