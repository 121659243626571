import React from 'react';

const Road8SVG = () => (
    <svg width="494" height="3535" viewBox="0 0 494 3535" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2763_881)">
            <path
                d="M263.994 0L197.089 38.8706C192.031 41.8087 192.064 49.1238 197.147 52.0167L318.84 121.275C323.931 124.172 323.953 131.503 318.88 134.432L215.928 193.859L112.975 253.287C107.876 256.23 107.931 263.61 113.075 266.477L363.003 405.049C368.217 407.873 368.317 415.32 363.181 418.283L289.481 460.803C287.163 462.14 284.312 462.156 281.979 460.846L217.131 424.425C214.825 423.13 212.011 423.13 209.705 424.425L152.658 456.464C147.544 459.337 147.488 466.68 152.559 469.629L191.165 492.088C196.216 495.026 196.186 502.332 191.11 505.228L105.825 553.888C100.735 556.792 100.723 564.126 105.803 567.047L183.417 611.809L292.888 674.945C297.931 677.881 297.906 685.175 292.843 688.076L117.218 788.713C112.137 791.625 112.134 798.953 117.213 801.868L212.35 856.479C214.695 857.825 217.579 857.821 219.919 856.469L300.454 809.927C302.793 808.575 305.675 808.57 308.019 809.914L400.464 862.925C405.538 865.835 405.546 873.151 400.479 876.072L260.917 956.669"
                stroke="#29C129" stroke-width="7.58243"/>
        </g>
        <defs>
            <clipPath id="clip0_2763_881">
                <rect width="494" height="3535" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Road8SVG;